<template>
  <div>
    <b-card title="Hızlı Fatura Düzenle">
      <h5>{{ fatBilgi.fatno }}</h5>
      <h6>{{ fatBilgi.carAd }}</h6>

      <b-form>
        <b-form-group
          id="input-group-1"
          label="Vade Tarihi"
          label-for="vadeTarihi"
          description="Özel Vade Tarihi Belirleyebilirsiniz"
        >
          <b-form-datepicker
            v-model="fatBilgi.vade"
            id="vadeTarihi"
            :date-format-options="{
              day: 'numeric',
              month: 'numeric',
              year: 'numeric',
            }"
            locale="tr"
          ></b-form-datepicker>
        </b-form-group>

        <b-form-group
          id="input-group-2"
          label="Kategorileri"
          label-for="input-2"
          description="Aralarında , (virgül) işareti koyarak birden fazla kategori tanımlayabilirsiniz."
        >
          <b-form-input
            id="input-2"
            v-model="fatBilgi.kat"
            placeholder="Kategoriler"
          ></b-form-input>
        </b-form-group>
      </b-form>
      <div>
        <p>
          <b-button
            block
            variant="primary"
            class="btn btn-block w-100"
            @click="faturaBilgiKaydet"
            >KAYDET</b-button
          >
        </p>
      </div>
    </b-card>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    fatBilgi: null,
  },
  data() {
    return {};
  },
  methods: {
    faturaBilgiKaydet() {
      var rot = this;

      let tokeni = rot.$store.state.userToken;
      rot.$store.state.loadAnimAc = true;
      var urlsi = "/api/faturasRoute/faturaBilgiDuzenle";
      var qs = require("qs");
      var useri = {
        userMail: rot.$store.state.userMail,
        userSifre: rot.$store.state.userSifre,
        sirketId: rot.$store.state.calisilanSirketId,
      };
      var faturai = {
        Id: rot.fatBilgi.fatid,
        kategori: rot.fatBilgi.kat,
        ozelVadeTarihi: rot.fatBilgi.vade,
      };

      axios
        .post(urlsi, qs.stringify({ user: useri, faturaBilgi: faturai }), {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + tokeni,
          },
        })
        .then((res) => {
          rot.$store.state.loadAnimAc = false;
          if (res.data.sonuc) {
            var ind = rot.$store.state.tumFaturalar.findIndex(
              (o) => o.Id == rot.fatBilgi.fatid
            );
            rot.$store.state.tumFaturalar[ind].kategorileri = rot.fatBilgi.kat;
            rot.$store.state.tumFaturalar[ind].vadeTarihi = rot.fatBilgi.vade;
          } else {
            rot.$dialog.alert(res.data.mesaj, { okText: "Tamam" });
          }
        })
        .catch(function (error) {
          rot.$store.state.loadAnimAc = false;
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    },
  },
};
</script>

<style scoped></style>
